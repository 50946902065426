"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _OrderFood = _interopRequireDefault(require("./OrderFood"));
var _OrderDelivery = _interopRequireDefault(require("./OrderDelivery"));
var _OrderTransport = _interopRequireDefault(require("./OrderTransport"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    driverId: Number
  },
  components: {
    OrderFood: _OrderFood.default,
    OrderDelivery: _OrderDelivery.default,
    OrderTransport: _OrderTransport.default
  }
};