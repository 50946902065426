var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Thêm điểm giao" : "Cập nhật điểm giao",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "Địa chỉ giao hàng", prop: "endAddress" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Địa chỉ")]),
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "line-height": "12px",
                            "z-index": "1000",
                            "margin-left": "4px",
                          },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleChangeAddress },
                        },
                        [
                          _c("i", { staticClass: "el-icon-edit" }),
                          _vm._v(" Nhập địa chỉ tại đây"),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-input", {
                staticClass: "bg-readonly",
                attrs: { readonly: "" },
                model: {
                  value: _vm.form.endAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "endAddress", $$v)
                  },
                  expression: "form.endAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Họ tên người nhận",
                        prop: "receiverName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.receiverName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiverName", $$v)
                          },
                          expression: "form.receiverName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Số điện thoại người nhận",
                        prop: "receiverPhone",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.receiverPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiverPhone", $$v)
                          },
                          expression: "form.receiverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trọng lượng", prop: "weight" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "weight", $$v)
                            },
                            expression: "form.weight",
                          },
                        },
                        _vm._l(_vm.weights, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại hàng", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        },
                        _vm._l(_vm.merchandises, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tiền hàng", prop: "moneyProduct" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.moneyProduct,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "moneyProduct", $$v)
                  },
                  expression: "form.moneyProduct",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
      _c("map-modal", { ref: "MapModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }