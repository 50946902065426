"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _withdraw = _interopRequireDefault(require("@/views/withdraw"));
var _deposit = _interopRequireDefault(require("@/views/deposit"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    driverId: Number
  },
  components: {
    Withdraw: _withdraw.default,
    Deposit: _deposit.default
  }
};