"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      orderType: "orderFood",
      orderTypes: [{
        name: "Thức ăn",
        key: "orderFood"
      }, {
        name: "Giao hàng",
        key: "orderDelivery"
      }, {
        name: "Di chuyển",
        key: "orderTransport"
      }],
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;
      dashbord.top5Customer().then(function (res) {
        _this.tableData = res.data.top5;
      });
    }
  }
};