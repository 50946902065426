var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm code, tên, sđt",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái đơn hàng"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái đơn hàng" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.ORDER_FOOD_STATUS, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "12px" },
          attrs: {
            type: "primary",
            size: "small",
            disabled: !_vm.selectedOrders.length,
            loading: _vm.loadingAccept,
          },
          on: { click: _vm.handleAccept },
        },
        [_vm._v("Xác nhận " + _vm._s(_vm.selectedOrders.length) + " nhận đơn")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          attrs: { data: _vm.tableData, border: "", fit: "", "row-key": "id" },
          on: { "selection-change": _vm.handleSelect },
        },
        [
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              type: "selection",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Code",
              prop: "code",
              "min-width": "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-code" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                        ]),
                      ]),
                    ]),
                    row.status == "WAITING"
                      ? _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: row.loadingAccept,
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(row)
                                  },
                                },
                              },
                              [_vm._v("Huỷ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: row.loadingAccept,
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSchedule(row)
                                  },
                                },
                              },
                              [_vm._v("Xác nhận đơn")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.status == "SCHEDULE"
                      ? _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: row.loadingAccept,
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAcceptSingle(row)
                                  },
                                },
                              },
                              [_vm._v("Đã đóng gói")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "startName", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { padding: "0" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khách hàng: "),
                        ]),
                        row.customer
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(row.customer.name) +
                                  " - " +
                                  _vm._s(row.customer.phone) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("--")]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tài xế: "),
                        ]),
                        row.driver
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(row.driver.name) +
                                  " - " +
                                  _vm._s(row.driver.phone) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("--")]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Từ: ")]),
                        _c("span", [_vm._v(_vm._s(row.startAddress))]),
                      ]),
                      !!row.endAddress
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Đến: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.endAddress))]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khoảng cách: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatNumberVN(row.distance)) + " Km"
                          ),
                        ]),
                      ]),
                      row.vehicle
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phương tiện: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.vehicle.name) +
                                  " " +
                                  _vm._s(
                                    row.vehicle.subName &&
                                      " | " + row.vehicle.subName
                                  )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Trạng thái: "),
                        ]),
                        _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    _vm.tagOptions[row.status] &&
                                    _vm.tagOptions[row.status].type,
                                  effect:
                                    _vm.tagOptions[row.status] &&
                                    _vm.tagOptions[row.status].effect,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.ORDER_FOOD_STATUS[row.status])
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      row.imagePickup
                        ? _c(
                            "li",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Ảnh lấy hàng: "),
                              ]),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleViewImage(row)
                                    },
                                  },
                                },
                                [_vm._v(" Xem ảnh ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Chi tiết",
              "min-width": "300px",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticClass: "food-list" },
                      _vm._l(row.orderFoodDetails, function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "food-item" },
                          [
                            _c("div", [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.storeProduct.name)),
                              ]),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(" x " + _vm._s(item.quantity)),
                              ]),
                              item.storeProductSubs.length
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "single-price" },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              item.storeProductSubs
                                                .map(function (e) {
                                                  return e.name
                                                })
                                                .join(", ")
                                            ) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", [
                                item.totalPrice
                                  ? _c(
                                      "span",
                                      { staticClass: "single-price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toThousandFilter")(
                                              item.totalPrice
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "single-price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toThousandFilter")(
                                              item.finalPrice +
                                                (item.storeProductSubs &&
                                                  item.storeProductSubs.reduce(
                                                    function (prev, cur) {
                                                      return prev + cur.price
                                                    },
                                                    0
                                                  ))
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                              ]),
                            ]),
                            _c("div", [
                              item.totalPrice
                                ? _c("span", { staticClass: "price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousandFilter")(
                                          item.quantity * item.totalPrice
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", { staticClass: "price" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toThousandFilter")(
                                          item.quantity *
                                            (item.finalPrice +
                                              (item.storeProductSubs &&
                                                item.storeProductSubs.reduce(
                                                  function (prev, cur) {
                                                    return prev + cur.price
                                                  },
                                                  0
                                                )))
                                        )
                                      )
                                    ),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("el-divider"),
                    _c(
                      "div",
                      {
                        staticClass: "bill-detail",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tạm tính: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.moneyProductOrigin)
                            )
                          ),
                        ]),
                      ]
                    ),
                    row.moneyDiscountProduct && row.fclassPay == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "bill-detail",
                            staticStyle: { "text-align": "right" },
                          },
                          [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v("Cửa hàng giảm "),
                                row.promotion
                                  ? [
                                      _vm._v(
                                        "(#" + _vm._s(row.promotion.code) + ")"
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" : "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "Khuyến mãi do cửa hàng tự tạo ra, và số tiền cửa hàng tự chịu",
                                      placement: "top",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              2
                            ),
                            _c(
                              "span",
                              { staticClass: "text-bold text-danger" },
                              [
                                _vm._v(
                                  "-" +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(
                                        row.moneyDiscountProduct
                                      )
                                    )
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    row.fclassPay
                      ? _c(
                          "div",
                          {
                            staticClass: "bill-detail",
                            staticStyle: { "text-align": "right" },
                          },
                          [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v("Fclass khuyến mãi "),
                                row.promotion
                                  ? [
                                      _vm._v(
                                        "(#" + _vm._s(row.promotion.code) + ")"
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(": "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        "Khuyến mãi do Fclass tạo ra, và số tiền khuyến mãi sẽ được chuyển vào ví chính của cửa hàng",
                                      placement: "top",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-question" })]
                                ),
                              ],
                              2
                            ),
                            _c(
                              "span",
                              { staticClass: "text-bold text-success" },
                              [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      _vm._f("toThousandFilter")(row.fclassPay)
                                    )
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "bill-detail",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khách hàng trả: "),
                        ]),
                        _c("span", { staticClass: "text-bold text-success" }, [
                          _vm._v(
                            "+" +
                              _vm._s(
                                _vm._f("toThousandFilter")(row.moneyProduct)
                              )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "bill-detail",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Cửa hàng nhận: "),
                        ]),
                        _c("span", { staticClass: "text-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toThousandFilter")(
                                  row.moneyProduct + row.fclassPay
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }