"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var authApi = exports.authApi = {
  register: function register(data) {
    return (0, _request.default)({
      url: '/auth/register',
      method: 'post',
      data: data
    });
  }
};