var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-form bg-yellow" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên cửa hàng", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Số điện thoại", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Email", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium" },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "el-form-item",
            { attrs: { label: "Tên đăng nhập", prop: "username" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      false
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mật khẩu", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Nhập lại mật khẩu", prop: "rePassword" },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "password" },
                        model: {
                          value: _vm.form.rePassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "rePassword", $$v)
                          },
                          expression: "form.rePassword",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "Mô tả", size: "normal" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 4 },
              placeholder:
                "Mô tả về lĩnh vực, nhóm sản phẩm kinh doanh của bạn",
              clearable: "",
            },
            model: {
              value: _vm.form.note,
              callback: function ($$v) {
                _vm.$set(_vm.form, "note", $$v)
              },
              expression: "form.note",
            },
          }),
        ],
        1
      ),
      _c(
        "section",
        [
          _vm._m(1),
          _c(
            "el-form-item",
            {
              attrs: { label: "Địa chỉ", prop: "address" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Địa chỉ")]),
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "line-height": "12px",
                            "z-index": "1000",
                            "margin-left": "4px",
                          },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleChangeAddress },
                        },
                        [
                          _c("i", { staticClass: "el-icon-edit" }),
                          _vm._v(" Nhập địa chỉ tại đây"),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-input", {
                staticClass: "bg-readonly",
                attrs: { size: "medium", readonly: "" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tỉnh thành", prop: "cityId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", size: "medium" },
                  on: { change: _vm.handleChangeCity },
                  model: {
                    value: _vm.form.cityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cityId", $$v)
                    },
                    expression: "form.cityId",
                  },
                },
                _vm._l(_vm.cities, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Quận huyện", prop: "districtId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "medium", filterable: "" },
                  on: { change: _vm.handleChangeDistrict },
                  model: {
                    value: _vm.form.districtId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "districtId", $$v)
                    },
                    expression: "form.districtId",
                  },
                },
                _vm._l(_vm.districts, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Phường xã", prop: "wardId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "medium", filterable: "" },
                  on: { change: _vm.handleChangeWard },
                  model: {
                    value: _vm.form.wardId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "wardId", $$v)
                    },
                    expression: "form.wardId",
                  },
                },
                _vm._l(_vm.wards, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Giờ mở cửa (Khung 1)", prop: "open" } },
                [
                  _c("el-time-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "medium",
                      placeholder: "Thời gian mở cửa",
                      "picker-options": {
                        start: "00:00",
                        step: "00:30",
                        end: "23:30",
                      },
                    },
                    model: {
                      value: _vm.form.open,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "open", $$v)
                      },
                      expression: "form.open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "close", label: "Giờ đóng cửa (Khung 1)" } },
                [
                  _c("el-time-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "medium",
                      placeholder: "Thời gian đóng cửa",
                      "picker-options": {
                        start: "00:00",
                        step: "00:30",
                        end: "23:30",
                        minTime: _vm.form.open,
                      },
                    },
                    model: {
                      value: _vm.form.close,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "close", $$v)
                      },
                      expression: "form.close",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Giờ mở cửa (Khung 2)", prop: "open2" } },
                [
                  _c("el-time-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "medium",
                      placeholder: "Thời gian mở cửa",
                      "picker-options": {
                        start: "00:00",
                        step: "00:30",
                        end: "23:30",
                      },
                    },
                    model: {
                      value: _vm.form.open2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "open2", $$v)
                      },
                      expression: "form.open2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "close2", label: "Giờ đóng cửa (Khung 2)" } },
                [
                  _c("el-time-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "medium",
                      placeholder: "Thời gian đóng cửa",
                      "picker-options": {
                        start: "00:00",
                        step: "00:30",
                        end: "23:30",
                        minTime: _vm.form.open2,
                      },
                    },
                    model: {
                      value: _vm.form.close2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "close2", $$v)
                      },
                      expression: "form.close2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Hình ảnh hiển thị trên app", prop: "thumbnail" } },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.thumbnail, width: 200, height: 200 },
            on: { "upload:success": _vm.handleUploadSuccess },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Hình ảnh thực tế", prop: "avatar" } },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.avatar, width: 200, height: 200 },
            on: { "upload:success": _vm.handleUploadAvatarSuccess },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Giấy CN an toàn vệ sinh thực phẩm (nếu có)" } },
        [
          _c("single-image-s-3", {
            attrs: {
              pathImage: _vm.form.safetyCertification,
              width: 200,
              height: 200,
            },
            on: { "upload:success": _vm.handleCertification },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Giấy CN an toàn vệ sinh thực phẩm 2 (nếu có)" } },
        [
          _c("single-image-s-3", {
            attrs: {
              pathImage: _vm.form.safetyCertification2,
              width: 200,
              height: 200,
            },
            on: { "upload:success": _vm.handleCertification2 },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mã giới thiệu (Từ cửa hàng)" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.refCode,
              callback: function ($$v) {
                _vm.$set(_vm.form, "refCode", $$v)
              },
              expression: "form.refCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mã giới thiệu (Từ tài xế)" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.refCodeDriver,
              callback: function ($$v) {
                _vm.$set(_vm.form, "refCodeDriver", $$v)
              },
              expression: "form.refCodeDriver",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mã giới thiệu (Từ khách hàng)" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.refCodeCustomer,
              callback: function ($$v) {
                _vm.$set(_vm.form, "refCodeCustomer", $$v)
              },
              expression: "form.refCodeCustomer",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mã giới thiệu (Từ tổ chức/hộ gia đình)" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.refCodeCompany,
              callback: function ($$v) {
                _vm.$set(_vm.form, "refCodeCompany", $$v)
              },
              expression: "form.refCodeCompany",
            },
          }),
        ],
        1
      ),
      _c("map-modal", { ref: "MapModal" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("img", {
        staticStyle: { "vertical-align": "middle" },
        attrs: { width: "40", src: require("@/assets/images/icon.png") },
      }),
      _c("span", { staticStyle: { "vertical-align": "middle" } }, [
        _vm._v("Thông tin cửa hàng"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("img", {
        attrs: { width: "30", src: require("@/assets/images/location.svg") },
      }),
      _vm._v(" Địa chỉ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }