var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pack-box" }, [
    _c(
      "div",
      { staticClass: "icon--edit", on: { click: _vm.onUpdate } },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Sửa", placement: "top", effect: "dark" } },
          [_c("i", { staticClass: "el-icon-edit" })]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "icon--delete", on: { click: _vm.handleDelete } },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Xóa", placement: "top", effect: "dark" } },
          [_c("i", { staticClass: "el-icon-delete" })]
        ),
      ],
      1
    ),
    _c("h3", { staticClass: "title" }, [
      _vm._v("Điểm giao #" + _vm._s(_vm.index + 1)),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", { attrs: { width: "80", src: _vm.packageIcon, alt: "" } }),
      ]),
      _c("div", [
        _c("div", [
          _c("label", { attrs: { for: "" } }, [_vm._v("Người nhận: ")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.detail.receiverName) +
                " - " +
                _vm._s(_vm.detail.receiverPhone)
            ),
          ]),
        ]),
        _c("div", [
          _c("label", { attrs: { for: "" } }, [_vm._v("Địa chỉ: ")]),
          _c("span", [_vm._v(_vm._s(_vm.detail.endAddress))]),
        ]),
        _vm.detail.distance
          ? _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Khoảng cách: ")]),
              _c("span", [_vm._v(_vm._s(_vm.detail.distance) + " km")]),
            ])
          : _vm._e(),
        _c("hr"),
        _c("div", [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.detail.name)),
          ]),
          _c("br"),
          _c("span", [
            _vm._v("Trọng lượng: " + _vm._s(_vm.detail.weight) + "Kg"),
          ]),
        ]),
        _c("hr"),
        _c("div", [
          _c("label", { attrs: { for: "" } }, [_vm._v("Tiền hàng")]),
          _c("br"),
          _c("span", [
            _vm._v(_vm._s(_vm.$formatNumberVN(_vm.detail.moneyProduct)) + " đ"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }