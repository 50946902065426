var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-product" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Hiển thị mã giảm giá và các sản phẩm áp dụng")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Sản phẩm được áp dụng" } },
                [
                  _vm.form.promotion.productApplyType ==
                  _vm.PromotionProductApplyType.All
                    ? _c("div", [_vm._v("Tất cả sản phẩm")])
                    : [
                        _c(
                          "div",
                          { staticClass: "select-product" },
                          [
                            _vm.form.promotion.storeProducts &&
                            _vm.form.promotion.storeProducts.length
                              ? _c("span", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatNumberVN(
                                          _vm.form.promotion.storeProducts
                                            .length
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" Sản phẩm được chọn"),
                                ])
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  type: "primary",
                                  icon: "el-icon-plus",
                                },
                                on: { click: _vm.handleAddProduct },
                              },
                              [_vm._v("Thêm sản phẩm")]
                            ),
                          ],
                          1
                        ),
                        _vm.form.promotion.storeProducts &&
                        _vm.form.promotion.storeProducts.length
                          ? _c(
                              "el-table",
                              {
                                staticClass: "table-product",
                                staticStyle: { "margin-top": "12px" },
                                attrs: {
                                  data: _vm.form.promotion.storeProducts,
                                  border: "",
                                  fit: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Tên món",
                                    prop: "",
                                    width: "200px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "border-radius": "8px",
                                                    "object-fit": "contain",
                                                  },
                                                  attrs: {
                                                    width: "50",
                                                    src: _vm.$url.image(
                                                      row.thumbnail
                                                    ),
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "name-product",
                                                    staticStyle: {
                                                      "font-weight": "bold",
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    194563092
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { sortable: "", label: "Giá" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c("div", [
                                              _c("b", [_vm._v("Giá bán:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$formatNumberVN(
                                                      row.finalPrice
                                                    )
                                                  ) +
                                                  "đ"
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    615402327
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Hoạt động",
                                    width: "auto",
                                    "class-name": "small-padding fixed-width",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center",
                                                staticStyle: {
                                                  "margin-top": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "60px !important",
                                                    },
                                                    attrs: {
                                                      type: "danger",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDelete(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Xóa")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3551566871
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("product-table-modal", {
        ref: "ProductTableModal",
        on: { "submit:ok": _vm.handleSelectProducts },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }