"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find-index.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url.to-json.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _MultipleImageS = _interopRequireDefault(require("@/components/Upload/MultipleImageS3.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MultipleImageS3: _MultipleImageS.default
  },
  name: "EditorSlideUpload",
  props: {
    color: {
      type: String,
      default: "#1890ff"
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      dialogVisible: false,
      listObj: {},
      fileList: []
    };
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)({
    checkAllSuccess: function checkAllSuccess() {
      var _this = this;
      return Object.keys(this.listObj).every(function (item) {
        return _this.listObj[item].hasSuccess;
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.fileList.push(res);
    },
    handleRemove: function handleRemove(file) {
      var findIndex = this.fileList.findIndex(function (e) {
        return e.url == file.url;
      });
      if (findIndex > -1) {
        this.fileList.splice(findIndex, 1);
      }
    },
    handleSubmit: function handleSubmit() {
      // const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      var arr = this.fileList;
      if (!this.checkAllSuccess()) {
        this.$message("Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!");
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess: function handleSuccess(response, file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);
      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = this.baseUrlMedia + response.data.path;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    }
  }, "handleRemove", function handleRemove(file) {
    var uid = file.uid;
    var objKeyArr = Object.keys(this.listObj);
    for (var i = 0, len = objKeyArr.length; i < len; i++) {
      if (this.listObj[objKeyArr[i]].uid === uid) {
        delete this.listObj[objKeyArr[i]];
        return;
      }
    }
  }), "beforeUpload", function beforeUpload(file) {
    var _self = this;
    var _URL = window.URL || window.webkitURL;
    var fileName = file.uid;
    this.listObj[fileName] = {};
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        _self.listObj[fileName] = {
          hasSuccess: false,
          uid: file.uid,
          width: this.width,
          height: this.height
        };
      };
      resolve(true);
    });
  })
};