"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/signup",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/signup/index"));
    });
  },
  hidden: true,
  meta: {
    title: "Đăng ký đối tác F&B"
  }
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/product/list",
  hidden: true
}, {
  path: "/eco",
  component: _layout.default,
  permission: true,
  meta: {
    title: "Tài Chính",
    icon: "wallet"
  },
  children: [{
    path: "dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Doanh Thu"
    }
  }, {
    path: "bank-info",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bank-info"));
      });
    },
    name: "eco-bank-info",
    meta: {
      title: "Tài khoản ngân hàng"
    }
  }, {
    path: "deposit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/deposit-store"));
      });
    },
    name: "eco-deposit",
    meta: {
      title: "Nạp Tiền"
    }
  }, {
    path: "withdraw",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/withdraw-store"));
      });
    },
    name: "eco-withdraw",
    meta: {
      title: "Rút Tiền"
    }
  }, {
    path: "transaction",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction"));
      });
    },
    name: "eco-transaction",
    meta: {
      title: "Lịch Sử Giao Dịch"
    }
  }]
}, {
  path: "/product",
  component: _layout.default,
  permission: true,
  meta: {
    title: "Quản Lý Sản Phẩm",
    icon: "envelope-open"
  },
  children: [{
    path: "group",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store/components/MenuFood"));
      });
    },
    name: "product-group",
    meta: {
      title: "Nhóm sản phẩm"
    }
  }, {
    path: "list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/store/components/Food"));
      });
    },
    name: "product-list",
    meta: {
      title: "Tên Hàng"
    }
  }]
}, {
  path: "/order",
  component: _layout.default,
  permission: true,
  alwaysShow: true,
  meta: {
    title: "Quản Lý Đơn Hàng",
    icon: "file-invoice"
  },
  children: [{
    path: "all",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/food"));
      });
    },
    name: "order-all",
    meta: {
      title: "Tất Cả"
    }
  }]
}, {
  path: "/delivery-order",
  component: _layout.default,
  alwaysShow: true,
  permission: true,
  meta: {
    title: "Vận Chuyển",
    icon: "box-open"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order-delivery"));
      });
    },
    name: "profile-index",
    meta: {
      title: "Quản lý vận chuyển"
    }
  }]
}, {
  path: "/marketing",
  component: _layout.default,
  alwaysShow: true,
  permission: true,
  meta: {
    title: "Kênh Marketing",
    icon: "tag"
  },
  children: [{
    path: "promotion",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/promotion/index"));
      });
    },
    name: "promotion-index",
    meta: {
      title: "Khuyến Mãi"
    }
  }, {
    path: "promotion/create",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/create-voucher"));
      });
    },
    name: "promotion-create",
    meta: {
      title: "Tạo Khuyến Mãi"
    },
    hidden: true
  }, {
    path: "promotion/detail/:promotionId",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/create-voucher"));
      });
    },
    name: "promotion-detail",
    meta: {
      title: "Chi tiết Khuyến Mãi"
    },
    hidden: true
  }]
}, {
  path: "/setup",
  component: _layout.default,
  alwaysShow: true,
  permission: true,
  meta: {
    title: "Thiết Lập Shop",
    icon: "cog"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile"));
      });
    },
    name: "profile-index",
    meta: {
      title: "Thiết Lập Shop"
    }
  }]
},
// {
//   path: "/dashboard",
//   component: Layout,
//   redirect: "/dashboard/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "dashboard"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/dashboard"),
//       name: "dashboard-index",
//       meta: {
//         title: "Dashboard F&B"
//       }
//     }
//   ]
// },
// {
//   path: "/category",
//   component: Layout,
//   redirect: "/category/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "component"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/store/components/MenuFood"),
//       name: "category-index",
//       meta: {
//         title: "Nhóm hàng"
//       }
//     }
//   ]
// },
// {
//   path: "/food",
//   component: Layout,
//   redirect: "/food/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "example"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/store/components/Food"),
//       name: "food-index",
//       meta: {
//         title: "Tên hàng"
//       }
//     }
//   ]
// },
// {
//   path: "/order",
//   component: Layout,
//   redirect: "/order/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "food"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/order/food"),
//       name: "order-index",
//       meta: {
//         title: "Danh sách đơn hàng"
//       }
//     }
//   ]
// },

// {
//   path: "/review",
//   component: Layout,
//   redirect: "/review/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "food"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/review"),
//       name: "review-index",
//       meta: {
//         title: "Xem đánh giá"
//       }
//     }
//   ]
// },

// {
//   path: "/delivery-order",
//   component: Layout,
//   redirect: "/delivery/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "shipping"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/order-delivery"),
//       name: "delivery-order-index",
//       meta: {
//         title: "Đơn giao hàng"
//       }
//     }
//   ]
// },

// {
//   path: "/transaction",
//   component: Layout,
//   redirect: "/transaction/index",
//   permission: true,
//   meta: {
//     title: "Cửa hàng",
//     icon: "example"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/transaction"),
//       name: "transaction-index",
//       meta: {
//         title: "Lịch sử giao dịch"
//       }
//     }
//   ]
// },

// {
//   path: "/deposit",
//   component: Layout,
//   redirect: "/deposit/index",
//   permission: true,
//   meta: {
//     title: "Nạp tiền",
//     icon: "example"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/deposit-store"),
//       name: "deposit-index",
//       meta: {
//         title: "Nạp tiền"
//       }
//     }
//   ]
// },
// {
//   path: "/withdraw",
//   component: Layout,
//   redirect: "/withdraw/index",
//   permission: true,
//   meta: {
//     title: "Rút tiền",
//     icon: "example"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/withdraw-store"),
//       name: "withdraw-index",
//       meta: {
//         title: "Rút tiền"
//       }
//     }
//   ]
// },
{
  path: "/notification",
  redirect: "/notification/index",
  component: _layout.default,
  hidden: true,
  permission: true,
  meta: {
    title: "Thông báo",
    icon: "notification-fill"
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/index"));
      });
    },
    name: "news-index",
    meta: {
      title: "Tin tức"
    }
  }]
},
// {
//   path: "/promotion",
//   component: Layout,
//   redirect: "/promotion/index",
//   permission: true,
//   meta: {
//     title: "Khuyến mãi",
//     icon: "notification-fill"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/promotion/index"),
//       name: "promotion-index",
//       meta: {
//         title: "Khuyến mãi"
//       }
//     }
//   ]
// },
// {
//   path: "/profile",
//   component: Layout,
//   redirect: "/profile/index",
//   permission: true,
//   meta: {
//     title: "Nhà hàng",
//     icon: "qq"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/profile"),
//       name: "profile-index",
//       meta: {
//         title: "Thông tin Cty - Chủ SH - Cửa hàng/ Chi nhánh"
//       }
//     }
//   ]
// },

{
  path: "*",
  redirect: "/404",
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;