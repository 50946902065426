var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allow-direct-order" },
    [
      _c("label", { staticClass: "label", attrs: { for: "" } }, [
        _vm._v("Cấu hình nhận đơn"),
      ]),
      _c("el-switch", {
        staticStyle: { display: "block" },
        attrs: {
          "active-color": "#13ce66",
          "inactive-color": "#ff4949",
          "active-text": "Giao trực tiếp",
          "inactive-text": "Chờ xác nhận",
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.form.canDirectDelivery,
          callback: function ($$v) {
            _vm.$set(_vm.form, "canDirectDelivery", $$v)
          },
          expression: "form.canDirectDelivery",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }