var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _vm.userInfo.balance < 0
        ? _c("el-alert", {
            staticStyle: { "max-width": "calc(100% - 300px)" },
            attrs: {
              title:
                "Tài khoản của bạn đã bị âm " +
                _vm.$formatNumberVN(_vm.userInfo.balance) +
                "đ. Vui lòng nạp thêm tiền",
              type: "error",
              closable: false,
            },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-side" },
        [_c("news", { attrs: { onClickNews: _vm.handleClickNews } })],
        1
      ),
      _c("NewsModal", { ref: "NewsModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }