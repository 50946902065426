"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STATUS_ORDER = void 0;
var STATUS_ORDER = exports.STATUS_ORDER = {
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn hàng",
  COMPLETE: "Đã hoàn thành",
  RUN: "Đang di chuyển",
  CUSTOMER_CANCEL: "Người Dùng hủy chuyến",
  DRIVER_CANCEL: "Tài xế hủy",
  PENDING_PAYMENT: "Chờ thanh toán",
  PENDING: "Đang chờ",
  SCHEDULE: "Chờ xác nhận",
  STORE_CANCEL: 'Hủy',
  STAFF_CANCEL: 'Hệ thống hủy'
};