"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vehicleCityApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var vehicleCityApi = exports.vehicleCityApi = {
  find: function find(districtId) {
    return (0, _request.default)({
      url: '/vehicleCity/delivery',
      params: {
        districtId: districtId
      }
    });
  }
};