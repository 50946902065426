var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "create-delivery-order-modal",
      attrs: {
        title: "Thêm đơn giao hàng",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "start-address" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", { attrs: { width: "25", src: _vm.pointIcon } }),
        ]),
        _c("div", { staticClass: "content" }, [
          _c("span", [_vm._v("Nhận hàng ở")]),
          _c("label", { staticClass: "address-name", attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.profile.name)),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.profile.address))]),
        ]),
      ]),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "12px", "margin-bottom": "16px" },
          attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
          on: { click: _vm.handleCreateDeliveryAddress },
        },
        [_vm._v("Thêm điểm giao")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingEst,
              expression: "loadingEst",
            },
          ],
          staticStyle: { "min-height": "150px" },
        },
        [
          !_vm.form.details.length
            ? _c("div", { staticClass: "empty" }, [
                _c("img", { attrs: { src: _vm.packageIcon } }),
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Hãy thêm điểm giao"),
                ]),
              ])
            : _vm._l(_vm.form.details, function (item, index) {
                return _c("delivery-detail-pack", {
                  key: index,
                  attrs: { index: index, detail: item },
                  on: {
                    update: _vm.handleUpdateDetail,
                    delete: _vm.deleteDetail,
                  },
                })
              }),
        ],
        2
      ),
      _c("div", { staticClass: "payment" }, [
        _c("span", [_vm._v("Tổng chi phí")]),
        _c("label", [
          _vm._v(_vm._s(_vm.$formatNumberVN(_vm.form.order.moneyFinal)) + " đ"),
        ]),
      ]),
      false
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-top": "12px" },
              attrs: {
                type: "primary",
                size: "mini",
                disabled: _vm.loadingEst,
              },
              on: { click: _vm.estOrder },
            },
            [_vm._v("Tính tiền đơn")]
          )
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: { click: _vm.createData },
            },
            [_vm._v("Tạo đơn hàng")]
          ),
        ],
        1
      ),
      _c("delivery-address-modal", {
        ref: "DeliveryAddressModal",
        on: { create: _vm.addDetail, update: _vm.updateDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }