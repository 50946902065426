"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var callbacks = [];
function loadedTinymce() {
  // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2144
  // check is successfully downloaded script
  return window.tinymce;
}
var dynamicLoadScript = function dynamicLoadScript(src, callback) {
  var existingScript = document.getElementById(src);
  var cb = callback || function () {};
  if (!existingScript) {
    var script = document.createElement('script');
    script.src = src; // src url for the third-party library being loaded.
    script.id = src;
    document.body.appendChild(script);
    callbacks.push(cb);
    var onEnd = 'onload' in script ? stdOnEnd : ieOnEnd;
    onEnd(script);
  }
  if (existingScript && cb) {
    if (loadedTinymce()) {
      cb(null, existingScript);
    } else {
      callbacks.push(cb);
    }
  }
  function stdOnEnd(script) {
    script.onload = function () {
      // this.onload = null here is necessary
      // because even IE9 works not like others
      this.onerror = this.onload = null;
      var _iterator = (0, _createForOfIteratorHelper2.default)(callbacks),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _cb = _step.value;
          _cb(null, script);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      callbacks = null;
    };
    script.onerror = function () {
      this.onerror = this.onload = null;
      cb(new Error('Failed to load ' + src), script);
    };
  }
  function ieOnEnd(script) {
    script.onreadystatechange = function () {
      if (this.readyState !== 'complete' && this.readyState !== 'loaded') return;
      this.onreadystatechange = null;
      var _iterator2 = (0, _createForOfIteratorHelper2.default)(callbacks),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _cb2 = _step2.value;
          _cb2(null, script); // there is no way to catch loading errors in IE8
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      callbacks = null;
    };
  }
};
var _default = exports.default = dynamicLoadScript;