var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "news-item",
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.news)
        },
      },
    },
    [
      _c("div", { staticClass: "title-box" }, [
        _c("label", { staticClass: "title", attrs: { for: "" } }, [
          _vm._v(_vm._s(_vm.news.title)),
        ]),
      ]),
      _c("section", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
      _c("section", { staticClass: "date" }, [
        _c("span", { staticClass: "news-status" }, [_vm._v("Mới •")]),
        _c("span", { staticClass: "time" }, [
          _vm._v(
            _vm._s(
              _vm.$formatDateTime(_vm.news.dateCreated, "HH:mm, DD/MM/YYYY")
            )
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }