var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chọn sản phẩm",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
        id: "food-list",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      !_vm.isView
        ? _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Tìm tên sản phẩm",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.fetchData($event)
                      },
                    },
                    model: {
                      value: _vm.listQuery.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "search", $$v)
                      },
                      expression: "listQuery.search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.fetchData },
                },
                [_vm._v("Tìm kiếm")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          attrs: { data: _vm.data, border: "", fit: "" },
          on: { select: _vm.handleSelect },
        },
        [
          !_vm.isView
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "50" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Tên món", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name)),
                        ]),
                      ]),
                      _c("img", {
                        staticClass: "image",
                        attrs: { src: _vm.$url.image(row.thumbnail), alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Danh mục:")]),
                      _vm._v(
                        " " +
                          _vm._s(row.storeCategory && row.storeCategory.name)
                      ),
                    ]),
                    _c("div", [
                      _c("b", [_vm._v("Thành phần:")]),
                      _vm._v(" " + _vm._s(row.description)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("b", [_vm._v("Giá bán:")]),
                      _vm._v(
                        " " + _vm._s(_vm.$formatNumberVN(row.finalPrice)) + "đ"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isView
        ? _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.fetchData,
            },
          })
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.onSubmitOk()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }