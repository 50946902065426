"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findAll = findAll;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: '/store',
    method: 'get',
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/store',
    method: 'post',
    data: data
  });
}
function update(storeId, data) {
  return (0, _request.default)({
    url: "/store/".concat(storeId, "/update"),
    method: 'post',
    data: data
  });
}