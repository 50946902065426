var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 24 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "utensils", size: "3x" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng"),
                  ]),
                  _c("span", [_vm._v("Tổng")]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: {
                      "start-val": 0,
                      "end-val": _vm.dataSouce.count,
                      duration: 3200,
                    },
                  }),
                  _c("hr"),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng doanh thu")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSouce.total,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }