"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.orderDelivery = orderDelivery;
exports.orderFood = orderFood;
exports.orderTransport = orderTransport;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/customer",
    method: "get",
    params: listQuery
  });
}
function update(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}
function orderFood(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderFood"),
    params: listQuery
  });
}
function orderDelivery(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderDelivery"),
    params: listQuery
  });
}
function orderTransport(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderTransport"),
    params: listQuery
  });
}