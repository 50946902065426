"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var order = _interopRequireWildcard(require("@/api/order/transport"));
var driver = _interopRequireWildcard(require("@/api/driver"));
var customer = _interopRequireWildcard(require("@/api/customer"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _format = require("@/utils/format");
var _enum = require("@/utils/enum");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OrderTransport",
  components: {
    Pagination: _Pagination.default
  },
  props: {
    customerId: {
      type: Number,
      default: null
    },
    showStatusCol: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      totalMoney: 0,
      ORDER_DELIVERY_STATUS: _enum.ORDER_DELIVERY_STATUS,
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        status: null,
        isBlock: null
      },
      tableData: [],
      tagOptions: _enum.tagOptions
    };
  },
  watch: {
    customerId: function customerId() {
      this.getTableData();
    }
  },
  methods: {
    viewCustomer: function viewCustomer(customer) {
      this.$refs["customer-dialog"].handleEdit(customer);
    },
    viewDriver: function viewDriver(driver) {
      this.$refs["driver-dialog"].handleEdit(driver);
    },
    getTableDataByDriverId: function getTableDataByDriverId() {
      var _this = this;
      this.listLoading = true;
      driver.orderDelivery(this.driverId, this.listQuery).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.listLoading = false;
      });
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    changeStatus: function changeStatus(row) {
      var _this2 = this;
      this.listLoading = true;
      var data = {
        orderTransport: {
          status: row.status
        }
      };
      order.update(row.id, data).then(function () {
        _this2.$notify({
          type: "success",
          message: "Thành công"
        });
        _this2.getTableData();
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    getTotalMoney: function getTotalMoney() {
      var _this3 = this;
      var listQuery = {
        from: this.listQuery.from,
        to: this.listQuery.to
      };
      var data = [];
      order.findAll(listQuery).then(function (res) {
        data = res.data.data;
        var total = 0;
        data.forEach(function (element) {
          if (element.status == "COMPLETE") total += element.moneyFinal - element.moneyIncome;
        });
        _this3.totalMoney = total;
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;
      this.listLoading = true;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      customer.orderTransport(this.customerId, this.listQuery).then(function (res) {
        _this4.tableData = res.data.data;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};