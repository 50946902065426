var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách cửa hàng giới thiệu",
        visible: _vm.visible,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Cửa hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      attrs: {
                        width: "80",
                        src: _vm.$url.image(row.thumbnail),
                      },
                    }),
                    _c("div", [
                      _c(
                        "label",
                        { staticStyle: { color: "black" }, attrs: { for: "" } },
                        [_vm._v(" " + _vm._s(row.name) + " ")]
                      ),
                    ]),
                    row.code
                      ? _c("div", [
                          _c(
                            "label",
                            {
                              staticStyle: { color: "#000" },
                              attrs: { for: "" },
                            },
                            [_vm._v("Mã giới thiệu: ")]
                          ),
                          _c("span", [_vm._v(_vm._s(row.code))]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.address) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin liên hệ", prop: "website" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Số điện thoại: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.phone))]),
                    ]),
                    row.open && row.close
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Hoạt động: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(row.open) + " - " + _vm._s(row.close)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }