"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.to-string.js");
var _storeCategory = require("@/api/store-category");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogLoading: false,
      tempData: {},
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên danh mục",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    editData: function editData() {
      var _this = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          var data = {
            storeCategory: _this.tempData,
            storeId: _this.storeId
          };
          _this.loadingButton = true;
          _storeCategory.storeCategoryApi.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$message({
                type: "success",
                message: "Cập nhật danh mục thành công"
              });
              _this.dialogVisible = false;
              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs.dataForm.clearValidate();
        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;
      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.$nextTick(function () {
        _this3.$refs.dataForm.resetFields();
        _this3.tempData = {};
      });
    },
    createData: function createData() {
      var _this4 = this;
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          var data = {
            storeCategory: _this4.tempData,
            storeId: _this4.storeId
          };
          _this4.loadingButton = true;
          _storeCategory.storeCategoryApi.create(data).then(function (res) {
            if (res.status) {
              _this4.$message({
                type: "success",
                message: "Tạo danh mục thành công"
              });
              _this4.dialogVisible = false;
              _this4.$refs.dataForm.clearValidate();
              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};