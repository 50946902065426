var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { attrs: { "tab-position": "left" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thức ăn" } },
            [
              _c("order-food", {
                attrs: {
                  hideDriver: "",
                  customerId: _vm.customerId,
                  showStatusCol: true,
                },
                on: {
                  "update:customerId": function ($event) {
                    _vm.customerId = $event
                  },
                  "update:customer-id": function ($event) {
                    _vm.customerId = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Giao hàng" } },
            [
              _c("order-delivery", {
                attrs: { customerId: _vm.customerId, showStatusCol: true },
                on: {
                  "update:customerId": function ($event) {
                    _vm.customerId = $event
                  },
                  "update:customer-id": function ($event) {
                    _vm.customerId = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Di chuyển" } },
            [
              _c("order-transport", {
                attrs: { customerId: _vm.customerId, showStatusCol: true },
                on: {
                  "update:customerId": function ($event) {
                    _vm.customerId = $event
                  },
                  "update:customer-id": function ($event) {
                    _vm.customerId = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }