import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=90eb152a&scoped=true"
import script from "./BasicInfo.vue?vue&type=script&lang=ts"
export * from "./BasicInfo.vue?vue&type=script&lang=ts"
import style0 from "./BasicInfo.vue?vue&type=style&index=0&id=90eb152a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90eb152a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028fclass_store\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90eb152a')) {
      api.createRecord('90eb152a', component.options)
    } else {
      api.reload('90eb152a', component.options)
    }
    module.hot.accept("./BasicInfo.vue?vue&type=template&id=90eb152a&scoped=true", function () {
      api.rerender('90eb152a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/create-voucher/components/BasicInfo.vue"
export default component.exports