var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "20px" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("span", [_vm._v("Thông tin")])]
      ),
      _c("div", { staticClass: "user-profile" }, [
        _c(
          "div",
          { staticClass: "box-center" },
          [
            _c("single-image-s-3", {
              attrs: {
                pathImage:
                  _vm.user.avatar ||
                  require("@/assets/images/avatar_default.svg"),
                width: 100,
                height: 100,
              },
              on: {
                "upload:success": _vm.handleUploadSuccess,
                error: _vm.handleImgError,
              },
            }),
            _c("small", [_vm._v("Click vào ảnh để thay đổi avatar")]),
          ],
          1
        ),
        _c("div", { staticClass: "box-center" }, [
          _c("div", { staticClass: "user-name text-center" }, [
            _vm._v(_vm._s(_vm.user.name)),
          ]),
          _c("div", { staticClass: "user-role text-center text-muted" }, [
            _vm._v(_vm._s(_vm.user.address)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "user-bio" }, [
        _c("div", { staticClass: "user-education user-bio-section" }, [
          _c(
            "div",
            { staticClass: "user-bio-section-header" },
            [
              _c("svg-icon", { attrs: { "icon-class": "education" } }),
              _c("span", [_vm._v("Thông tin khác")]),
            ],
            1
          ),
          _c("div", { staticClass: "user-bio-section-body" }, [
            _c("div", { staticClass: "text-muted" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("Tình trạng: "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.user.status == _vm.StoreStatus.Offline
                      ? "Đóng cửa"
                      : "Mở cửa"
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "text-muted" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("Số điện thoại: "),
              ]),
              _vm._v(" " + _vm._s(_vm.user.phone) + " "),
            ]),
            _c("div", { staticClass: "text-muted" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("Ví chính: "),
              ]),
              _vm._v(
                " " + _vm._s(_vm.$formatNumberVN(_vm.user.balance)) + "đ "
              ),
            ]),
            _c("div", { staticClass: "text-muted" }, [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("Ví khuyến mãi: "),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.$formatNumberVN(_vm.user.balancePromotion)) +
                  "đ "
              ),
            ]),
            _c(
              "div",
              { staticClass: "text-muted" },
              [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Mã giới thiệu: "),
                ]),
                _vm._v(" " + _vm._s(_vm.user.code) + " "),
                _c(
                  "el-link",
                  { attrs: { type: "primary" }, on: { click: _vm.doCopy } },
                  [
                    _c("i", { staticClass: "el-icon-share" }),
                    _vm._v(" Chia sẻ"),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-muted" },
              [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("DS giới thiệu: "),
                ]),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.viewChildren($event)
                      },
                    },
                  },
                  [_vm._v(" Xem danh sách ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-muted" },
              [
                _c(
                  "el-link",
                  { attrs: { type: "primary" }, on: { click: _vm.doShare } },
                  [
                    _c("i", { staticClass: "el-icon-share" }),
                    _vm._v(" Chia sẻ cho khách hàng"),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-muted" },
              [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.doShareDriver },
                  },
                  [
                    _c("i", { staticClass: "el-icon-share" }),
                    _vm._v(" Chia sẻ cho tài xế"),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("store-children-modal", { ref: "StoreChildrenModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }