var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết hàng hoá",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
        id: "delivery-detail",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.formData.orderDeliveryDetails,
            border: "",
            fit: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "Người nhận", prop: "receiverName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Họ tên: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverName))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("SĐT: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ", prop: "endAddress" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(" " + _vm._s(row.endAddress) + " ")]),
                    row.moneyFinal
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Phí vận chuyển: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$formatNumberVN(row.moneyFinal)) +
                                "đ (" +
                                _vm._s(row.distance) +
                                "Km)"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hàng hoá", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(row.name) +
                          " - " +
                          _vm._s(row.weight) +
                          "Kg "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tiền hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.moneyProduct)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.STATUS_ORDER[row.status]) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hình ảnh giao hàng",
              prop: "status",
              width: "280",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.imageDelivered || _vm.formData.imageDelivered
                      ? _c(
                          "div",
                          {
                            staticClass: "item light-box-shadow",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { height: "250px" },
                              attrs: {
                                src:
                                  row.imageDelivered ||
                                  _vm.formData.imageDelivered,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "mask" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "icon-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleViewImage(
                                        row.imageDelivered ||
                                          _vm.formData.imageDelivered
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]),
                          ]
                        )
                      : _c("div", [_vm._v(" Không có ảnh ")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }