"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _config = require("@/api/config");
var _auth = require("@/utils/auth");
var _router = _interopRequireWildcard(require("@/router"));
var state = {
  config: {
    STORE_LIMIT: ""
  }
};
var mutations = {
  SET_CONFIG: function SET_CONFIG(state, config) {
    state.config = config;
  }
};
var actions = {
  // get user info
  getConfig: function getConfig(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _config.configApi.findAll().then(function (response) {
        var data = response.data;
        commit("SET_CONFIG", data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};