"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.findOne = findOne;
exports.getList = getList;
exports.promotionApi = void 0;
exports.remove = remove;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList(params) {
  return (0, _request.default)({
    url: "/promotion",
    method: "get",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/promotion",
    method: "post",
    data: data
  });
}
function update(promotionId, data) {
  return (0, _request.default)({
    url: "/promotion/".concat(promotionId, "/update"),
    method: "post",
    data: data
  });
}
function remove(promotionId) {
  return (0, _request.default)({
    url: "/promotion/".concat(promotionId, "/delete"),
    method: "post"
  });
}
function findOne(promotionId) {
  return (0, _request.default)({
    url: "/promotion/".concat(promotionId)
  });
}
var promotionApi = exports.promotionApi = {
  getList: getList,
  create: create,
  update: update,
  remove: remove,
  findOne: findOne
};