"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    customerId: Number
  },
  components: {
    OrderFood: function OrderFood() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderFood"));
      });
    },
    OrderDelivery: function OrderDelivery() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderDelivery"));
      });
    },
    OrderTransport: function OrderTransport() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderTransport"));
      });
    }
  }
};