var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "food-dialog",
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm món ăn" : "Cập nhật món ăn",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "Thông tin", name: "info" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dialogLoading,
                    expression: "dialogLoading",
                  },
                ],
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.tempData,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Tên món", prop: "name" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "name", $$v)
                                    },
                                    expression: "tempData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Danh mục",
                                  prop: "storeCategoryId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: _vm.tempData.storeCategoryId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "storeCategoryId",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.storeCategoryId",
                                    },
                                  },
                                  _vm._l(_vm.menuData, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Thành phần",
                                  prop: "description",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "description", $$v)
                                    },
                                    expression: "tempData.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Giá gốc",
                                  prop: "originPrice",
                                },
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: {
                                        alias: "decimal",
                                        groupSeparator: ".",
                                        rightAlign: false,
                                        autoGroup: true,
                                        repeat: 20,
                                        autoUnmask: true,
                                        allowMinus: false,
                                      },
                                      expression:
                                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.tempData.originPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "originPrice", $$v)
                                    },
                                    expression: "tempData.originPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Giá sau giảm giá",
                                  prop: "finalPrice",
                                },
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: {
                                        alias: "decimal",
                                        groupSeparator: ".",
                                        rightAlign: false,
                                        autoGroup: true,
                                        repeat: 20,
                                        autoUnmask: true,
                                        allowMinus: false,
                                      },
                                      expression:
                                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.tempData.finalPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "finalPrice", $$v)
                                    },
                                    expression: "tempData.finalPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Ghi chú", prop: "note" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea" },
                                  model: {
                                    value: _vm.tempData.note,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "note", $$v)
                                    },
                                    expression: "tempData.note",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "thumbnail" } },
                              [
                                _c("single-image-s-3", {
                                  attrs: {
                                    pathImage: _vm.tempData.thumbnail,
                                    width: 250,
                                    height: 250,
                                    prefixFile: _vm.PrefixImage.Food,
                                  },
                                  on: {
                                    "upload:success": _vm.handleUploadSuccess,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "subgroup", label: "Nhóm sản phẩm phụ" } },
                [
                  _c("sub-food-group", {
                    attrs: {
                      storeProductId: _vm.tempData.id,
                      disable: _vm.dialogDisabled,
                    },
                    on: {
                      "update:storeProductId": function ($event) {
                        return _vm.$set(_vm.tempData, "id", $event)
                      },
                      "update:store-product-id": function ($event) {
                        return _vm.$set(_vm.tempData, "id", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "subfood", label: "Sản phẩm phụ" } },
                [
                  _c("sub-food", {
                    attrs: {
                      storeProductId: _vm.tempData.id,
                      disable: _vm.dialogDisabled,
                    },
                    on: {
                      "update:storeProductId": function ($event) {
                        return _vm.$set(_vm.tempData, "id", $event)
                      },
                      "update:store-product-id": function ($event) {
                        return _vm.$set(_vm.tempData, "id", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "edit" ? _vm.editData() : _vm.createData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }