"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectTypeTrans = exports.SelectType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/defineProperty.js"));
var SelectType;
(function (SelectType) {
  SelectType["Checkbox"] = "CHECKBOX";
  SelectType["Radio"] = "RADIO";
})(SelectType || (exports.SelectType = SelectType = {}));
var SelectTypeTrans = exports.SelectTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, SelectType.Checkbox, 'Được chọn nhiều'), SelectType.Radio, 'Được chọn một');