var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          series: _vm.series,
          "data-chart": _vm.dataChart,
          "pointer-name": "Đơn hàng",
          "attr-get": "total",
          format: "currency",
          "bar-color": "#F4663E",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.dataChart = $event
          },
          "update:data-chart": function ($event) {
            _vm.dataChart = $event
          },
        },
      }),
      _c("p", { staticClass: "chart-title" }, [_vm._v("Biểu đồ đơn hàng ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }