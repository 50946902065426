"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["date"],
  data: function data() {
    return {
      dataSouce: {}
    };
  },
  components: {
    CountTo: _vueCountTo.default
  },
  watch: {
    date: {
      handler: function handler() {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var query = {
        from: this.date[0],
        to: this.date[1]
      };
      dashboard.getDashboard(query).then(function (res) {
        _this.dataSouce = res.data;
      });
    }
  }
};