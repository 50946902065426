"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderDeliveryApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
// @ts-check

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderDelivery",
    params: listQuery
  });
}
var orderDeliveryApi = exports.orderDeliveryApi = {
  findAll: findAll,
  findOne: function findOne(orderId) {
    return (0, _request.default)({
      url: "/orderDelivery/".concat(orderId)
    });
  },
  est: function est(data) {
    return (0, _request.default)({
      url: "/orderDelivery/estimate",
      method: "post",
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/orderDelivery",
      method: "post",
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/orderDelivery/".concat(id, "/cancel"),
      method: "post"
    });
  }
};