var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news" }, [
    _c(
      "section",
      { staticClass: "header" },
      [
        _c("label", { attrs: { for: "" } }, [_vm._v("Thông báo")]),
        _c(
          "router-link",
          { attrs: { to: "/notification" } },
          [_c("el-link", { attrs: { type: "primary" } }, [_vm._v(" Tất cả ")])],
          1
        ),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "body" },
      _vm._l(_vm.newsList, function (item, index) {
        return _c("NewsItem", {
          key: index,
          attrs: { onClick: _vm.onClickNews, news: item },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }