"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: '/orderDelivery',
    method: 'get',
    params: listQuery
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderDelivery/".concat(orderId, "/update"),
    method: 'post',
    data: data
  });
}