var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-config" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thiết lập mã giảm giá")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Loại giảm giá|Mức giảm",
                    prop: "promotion.discountValue",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                        },
                      ],
                      staticClass: "input-with-select",
                      model: {
                        value: _vm.form.promotion.discountValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.promotion, "discountValue", $$v)
                        },
                        expression: "form.promotion.discountValue",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { slot: "prepend" },
                          slot: "prepend",
                          model: {
                            value: _vm.form.promotion.discountType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.promotion, "discountType", $$v)
                            },
                            expression: "form.promotion.discountType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: _vm.DiscountType.Price,
                              label: "Theo số tiền",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: _vm.DiscountType.Percent,
                              label: "Theo phần trăm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("template", { slot: "append" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.promotion.discountType ==
                              _vm.DiscountType.Percent
                              ? "%"
                              : "đ"
                          )
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.form.promotion.discountType == _vm.DiscountType.Percent
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Mức giảm tối đa",
                        prop: "promotion.discountValueMax",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        attrs: { placeholder: "Để trống sẽ không áp dụng" },
                        model: {
                          value: _vm.form.promotion.discountValueMax,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.promotion,
                              "discountValueMax",
                              $$v
                            )
                          },
                          expression: "form.promotion.discountValueMax",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Giá trị đơn hàng tối thiểu",
                    prop: "promotion.conditionMinPrice",
                  },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    model: {
                      value: _vm.form.promotion.conditionMinPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.promotion, "conditionMinPrice", $$v)
                      },
                      expression: "form.promotion.conditionMinPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Số lần được sử dụng",
                    prop: "promotion.numberUses",
                  },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    model: {
                      value: _vm.form.promotion.numberUses,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.promotion, "numberUses", $$v)
                      },
                      expression: "form.promotion.numberUses",
                    },
                  }),
                  _vm.form.promotion.numberUses
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.form.promotion.numberUses) +
                            " lần/khách hàng "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }