var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sender-box" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.order,
            "label-position": "top",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Tài khoản người dùng",
                        prop: "customerId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "reserve-keyword": "",
                            placeholder: "Tìm sđt, tên",
                            remote: "",
                            "remote-method": _vm.fetchCustomer,
                            filterable: "",
                          },
                          model: {
                            value: _vm.order.customerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.order, "customerId", $$v)
                            },
                            expression: "order.customerId",
                          },
                        },
                        _vm._l(_vm.customers, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name + " - " + item.phone,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "senderName", label: "Người gửi" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.order.senderName,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "senderName", $$v)
                          },
                          expression: "order.senderName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Địa chỉ", prop: "startAddress" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Địa chỉ"),
                              ]),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-left": "4px",
                                  },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickAddress(_vm.order)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                  _vm._v(" Thay đổi"),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.order.startAddress },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "senderName", label: "Số điện thoại" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.order.senderPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.order, "senderPhone", $$v)
                          },
                          expression: "order.senderPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }