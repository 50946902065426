"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromotionTypeTrans = exports.PromotionType = exports.PromotionProductApplyType = exports.PromotionPeriodTypeTrans = exports.PromotionPeriodType = exports.PromotionOrderType = exports.DiscountType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_store/node_modules/@babel/runtime/helpers/defineProperty.js"));
var PromotionProductApplyType;
(function (PromotionProductApplyType) {
  PromotionProductApplyType["All"] = "ALL";
  PromotionProductApplyType["Selection"] = "SELECTION";
})(PromotionProductApplyType || (exports.PromotionProductApplyType = PromotionProductApplyType = {}));
var DiscountType;
(function (DiscountType) {
  DiscountType["Price"] = "PRICE";
  DiscountType["Percent"] = "PERCENT";
})(DiscountType || (exports.DiscountType = DiscountType = {}));
var PromotionPeriodType;
(function (PromotionPeriodType) {
  PromotionPeriodType["Day"] = "DAY";
  PromotionPeriodType["Week"] = "WEEK";
  PromotionPeriodType["Month"] = "MONTH";
  PromotionPeriodType["All"] = "ALL";
})(PromotionPeriodType || (exports.PromotionPeriodType = PromotionPeriodType = {}));
var PromotionOrderType;
(function (PromotionOrderType) {
  PromotionOrderType["Order"] = "ORDER";
  PromotionOrderType["OrderFood"] = "ORDER_FOOD";
  PromotionOrderType["OrderDelivery"] = "ORDER_DELIVERY";
})(PromotionOrderType || (exports.PromotionOrderType = PromotionOrderType = {}));
var PromotionType;
(function (PromotionType) {
  PromotionType["Ship"] = "SHIP";
  PromotionType["Product"] = "PRODUCT";
})(PromotionType || (exports.PromotionType = PromotionType = {}));
var PromotionTypeTrans = exports.PromotionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, PromotionType.Product, 'Tiền hàng'), PromotionType.Ship, 'Phí ship');
var PromotionPeriodTypeTrans = exports.PromotionPeriodTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, PromotionPeriodType.All, 'Cả chương trình'), PromotionPeriodType.Day, 'Theo ngày'), PromotionPeriodType.Week, 'Theo tuần'), PromotionPeriodType.Month, 'Theo tháng');