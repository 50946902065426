var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm code",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái đơn hàng"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái đơn hàng" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.STATUS_ORDER, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: {
                type: "primary",
                icon: "el-icon-plus",
                loading: _vm.loadingExcel,
              },
              on: { click: _vm.createOrder },
            },
            [_vm._v(" Tạo đơn ")]
          ),
        ],
        1
      ),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Thông tin đơn giao hàng"),
        ]),
        _c("ul", [
          _c("li", [
            _c("label", { attrs: { for: "" } }, [_vm._v("Phí ship mỗi đơn: ")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$formatNumberVN(_vm.profile.shippingPartnerFee)) +
                  " đ"
              ),
            ]),
          ]),
          _c("li", [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Số đơn tối thiểu mỗi chuyến: "),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$formatNumberVN(_vm.profile.partnerMinOrderDetails)
                ) + " đơn"
              ),
            ]),
          ]),
          _c("li", [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Số KM tối đa của chuyến : "),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$formatNumberVN(_vm.profile.partnerMaxTotalKM)) +
                  " Km"
              ),
            ]),
          ]),
          _c("li", [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Số KM tối đa của mỗi đơn: "),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$formatNumberVN(_vm.profile.partnerMaxKM)) + " Km"
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Code",
              prop: "code",
              "min-width": "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-code" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _c("b", [_vm._v(_vm._s(row.codeContract || "--"))]),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          row.status != "STAFF_CANCEL" &&
                          row.status != "STORE_CANCEL" &&
                          row.status != "CUSTOMER_CANCEL" &&
                          row.status != "DRIVER_CANCEL" &&
                          row.status != "COMPLETE"
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "danger",
                                    loading: row.loadingDelete,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCancel(row)
                                    },
                                  },
                                },
                                [_vm._v(" Huỷ đơn ")]
                              )
                            : _vm._e(),
                          row.status == "STAFF_CANCEL" ||
                          row.status == "STORE_CANCEL" ||
                          row.status == "CUSTOMER_CANCEL" ||
                          row.status == "DRIVER_CANCEL" ||
                          row.status == "COMPLETE"
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRenew(row)
                                    },
                                  },
                                },
                                [_vm._v("Đặt lại đơn")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "startName", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { padding: "0" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Người gửi: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.senderName) +
                              " - " +
                              _vm._s(row.senderPhone)
                          ),
                        ]),
                      ]),
                      !!row.receiverName
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Người nhận: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.receiverName) +
                                  " - " +
                                  _vm._s(row.receiverPhone)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "li",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tài xế: "),
                          ]),
                          row.driver
                            ? _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(row.driver.name) +
                                    " - " +
                                    _vm._s(row.driver.phone) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Điểm lấy hàng: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.startAddress))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khoảng cách: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.distance)) + " Km"
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Trạng thái: "),
                        ]),
                        _c("span", [
                          _c(
                            "b",
                            {
                              class:
                                "color-" + _vm.tagOptions[row.status].color,
                            },
                            [_vm._v(_vm._s(_vm.STATUS_ORDER[row.status]))]
                          ),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              "Các điểm giao (" +
                                _vm._s(row.orderDeliveryDetails.length) +
                                "): "
                            ),
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs["detail-dialog"].handleView(
                                    row
                                  )
                                },
                              },
                            },
                            [_vm._v(" Chi tiết ")]
                          ),
                        ],
                        1
                      ),
                      row.imagePickup
                        ? _c(
                            "li",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Ảnh lấy hàng: "),
                              ]),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleViewImage(row)
                                    },
                                  },
                                },
                                [_vm._v(" Xem ảnh ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thanh toán", "min-width": "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền hàng:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.moneyProduct)) + " đ"
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phí ship:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.moneyDistance)) +
                              " đ"
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { display: "none" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
      _c("delivery-detail-dialog", { ref: "detail-dialog" }),
      _c("ImagesOrder", { ref: "images-dialog" }),
      _c("create-delivery-order-modal", {
        ref: "CreateDeliveryOrderModal",
        on: { "submit:ok": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }