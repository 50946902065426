var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delivery-order-item" },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v("Đơn hàng #" + _vm._s(_vm.order.id)),
      ]),
      _c(
        "div",
        { staticClass: "pricing" },
        [
          _c(
            "div",
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Người chịu phí: ")]),
              _c(
                "el-radio",
                {
                  attrs: { label: _vm.PaymentBy.Sender },
                  on: {
                    change: function ($event) {
                      return _vm.onSelectPaymentBy(_vm.order)
                    },
                  },
                  model: {
                    value: _vm.order.paymentBy,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "paymentBy", $$v)
                    },
                    expression: "order.paymentBy",
                  },
                },
                [_vm._v("Người gửi")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: _vm.PaymentBy.Receiver },
                  on: {
                    change: function ($event) {
                      return _vm.onSelectPaymentBy(_vm.order)
                    },
                  },
                  model: {
                    value: _vm.order.paymentBy,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "paymentBy", $$v)
                    },
                    expression: "order.paymentBy",
                  },
                },
                [_vm._v("Người nhận")]
              ),
            ],
            1
          ),
          _c("div", [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tổng tiền: ")]),
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "bold",
                  color: "black",
                  "font-size": "18px",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.order.moneyFinal
                      ? _vm.$formatNumberVN(_vm.order.moneyFinal)
                      : 0
                  ) + "đ"
                ),
              ]
            ),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "4px" },
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.onClickEst(_vm.order)
                },
              },
            },
            [_vm._v("Tính tiền đơn này")]
          ),
        ],
        1
      ),
      _c("sender-delivery", {
        attrs: { onClickAddress: _vm.onClickStartAddress, order: _vm.order },
      }),
      _c(
        "el-collapse",
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "Danh sách điểm giao" } },
            _vm._l(_vm.order.orderDeliveryDetails, function (item, index) {
              return _c("delivery-detail-pack", {
                key: index,
                attrs: {
                  detail: item,
                  onClickAddress: _vm.handleClickAddressDetail,
                  index: index,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }