var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("el-date-picker", {
        staticClass: "filter-item",
        attrs: {
          type: "daterange",
          "range-separator": "To",
          format: "dd/MM/yyyy",
          "value-format": "yyyy-MM-dd",
          "start-placeholder": "Ngày bắt đầu",
          "end-placeholder": "Ngày kết thúc",
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _c("panel-group", { attrs: { date: _vm.date } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }