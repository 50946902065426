var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "basic-info" } },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always", "body-style": { padding: "20px" } } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("h2", [_vm._v("Thông tin cơ bản")]),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Loại mã",
                    prop: "promotion.productApplyType",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.promotion.productApplyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.promotion, "productApplyType", $$v)
                        },
                        expression: "form.promotion.productApplyType",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: _vm.PromotionProductApplyType.All } },
                        [_vm._v("Khuyến mãi toàn cửa hàng")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: _vm.PromotionProductApplyType.Selection,
                          },
                        },
                        [_vm._v("Khuyến mãi sản phẩm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tiêu đề", prop: "promotion.title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.promotion.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.promotion, "title", $$v)
                      },
                      expression: "form.promotion.title",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Tên mã giảm giá sản phẩm không hiển thị với người mua"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Code", prop: "promotion.code" } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.promotion.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.promotion, "code", $$v)
                      },
                      expression: "form.promotion.code",
                    },
                  }),
                  _c("small", { staticStyle: { display: "block" } }, [
                    _vm._v(
                      "Vui lòng chỉ nhập các kí tự chữ cái (A-Z), số (0-9)"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Thời gian sử dụng",
                    prop: "promotion.start",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "To",
                      format: "dd/MM/yyyy HH:mm",
                      "value-format": "timestamp",
                      "start-placeholder": "Ngày bắt đầu",
                      "end-placeholder": "Ngày kết thúc",
                      "picker-options": {
                        disabledDate: _vm.handleDisableDate,
                      },
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mô tả", prop: "promotion.body" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { width: "100%", height: 400 },
                    on: { onInit: _vm.setBody },
                    model: {
                      value: _vm.form.promotion.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.promotion, "body", $$v)
                      },
                      expression: "form.promotion.body",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }